var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-code',{attrs:{"title":"Create customer"}},[_c('b-card-text',[_c('span',[_vm._v("Please enter customer details")])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Code"}},[_c('validation-provider',{attrs:{"name":"Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Customer code "},model:{value:(_vm.customer.code),callback:function ($$v) {_vm.$set(_vm.customer, "code", $$v)},expression:"customer.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h5',{staticClass:"text-primary font-weight-bolder"},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_vm._v(" Please enter buyer details ")],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Buyer name","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.buyer_name),callback:function ($$v) {_vm.$set(_vm.customer, "buyer_name", $$v)},expression:"customer.buyer_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address"}},[_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Buyer address","rows":"3"},model:{value:(_vm.customer.buyer_address),callback:function ($$v) {_vm.$set(_vm.customer, "buyer_address", $$v)},expression:"customer.buyer_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('h5',{staticClass:"text-primary font-weight-bolder"},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_vm._v(" Please enter importer details ")],1)]),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Importer name","state":errors.length > 0 ? false : null},model:{value:(_vm.customer.importer_name),callback:function ($$v) {_vm.$set(_vm.customer, "importer_name", $$v)},expression:"customer.importer_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Address"}},[_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":"Importer address","rows":"3"},model:{value:(_vm.customer.importer_address),callback:function ($$v) {_vm.$set(_vm.customer, "importer_address", $$v)},expression:"customer.importer_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addCustomer.apply(null, arguments)}}},[_vm._v(" Create ")]),_c('b-button',{attrs:{"variant":"outline-secondary","type":"reset","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'}},[_vm._v(" Reset ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-card-code title="Create customer">
    <b-card-text>
      <span>Please enter customer details</span>
    </b-card-text>

    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Code"
            >
              <validation-provider
                #default="{ errors }"
                name="Code"
              >
                <b-form-input
                  v-model="customer.code"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Customer code "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <h5 class="text-primary font-weight-bolder">
              <feather-icon icon="FileTextIcon" />
              Please enter buyer details
            </h5>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Name"
              label-for="Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
              >
                <b-form-input
                  v-model="customer.buyer_name"
                  placeholder="Buyer name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Address"
            >
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="customer.buyer_address"
                  placeholder="Buyer address"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col>
            <h5 class="text-primary font-weight-bolder">
              <feather-icon icon="FileTextIcon" />
              Please enter importer details
            </h5>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Name"
              label-for="Name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
              >
                <b-form-input
                  v-model="customer.importer_name"
                  placeholder="Importer name"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Address"
            >
              <validation-provider
                #default="{ errors }"
                name="Address"
              >
                <b-form-textarea
                  v-model="customer.importer_address"
                  placeholder="Importer address"
                  rows="3"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-button
              variant="primary"
              type="submit"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              @click.prevent="addCustomer"
            >
              Create
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              Reset
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BCardText,
  BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import axiosIns from '@/libs/axios'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BFormTextarea,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      types: [
        'supplier physique',
        'supplier morale',
      ],
      customer: {
        code: '',
        buyer_name: '',
        importer_name: '',
        buyer_address: '',
        importer_address: '',
      },
      required,
    }
  },

  methods: {
    async addCustomer() {
      await this.$http.post(
        '/customers/create/', this.customer,
      )
        .then(() => {
          this.$router.push('/customers/')
          setTimeout(() => {
            this.showToast('success', 'top-center', 'Customer successfully created')
          }, 1000)
        })
        .catch(err => {
          setTimeout(() => {
            this.showToast('danger', 'top-center', err.toString())
          }, 1000)
        })
    },

    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

</style>
